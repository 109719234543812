.footer{
  width: 100%;
}

.secondFooter{
  background-color: var(--color-red);
  width: 100%;
  padding: 60px 0;
}

.thirdFooter{
  background-color: var(--color-red-10);
  width: 100%;
  padding: 60px 0;
  border-top: 1px solid var(--color-red-10);
}

.thirdFooterContainer {
  display: flex;
  align-items: start;
  width: 100%;
  gap: 30px;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

/* Add these styles for the children */
.thirdFooterContainer > *:first-child {
  width: 200px;
  min-width: 200px;
}

.thirdFooterContainer > *:last-child {
  flex-grow: 1;
}

.thirdFooterContainer p {
  font-size: 14px;
  color: white;
}

.footerLink{
  font-size: 14px;
  display: flex;
  margin-top: 4px;
  font-family: var(--body-font);
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: white;

  &>div>div>img {
    filter: brightness(0) invert(1);
  }
}

.footerLinkImage{
  position: relative;
  width: 200px;
  height: 38px;
  transform: translateY(-5px);
}

.footerImage{
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 20%;

  
  @media screen and (min-width: 768px) {
    padding-top: 10%;
  }

  & > a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.footerLink:first-child{
  margin-top: 20px;
}

.footerLinks{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.footerLinkLabel{
  font-family: var(--header-font);
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
  color: white;
}

.footerGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;

  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.footerGridItem {
  width: 100%;
  height: 100px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.abtaAtol {
  object-fit: contain !important;
  height: 80px !important;
}